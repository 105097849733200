import * as THREE from "three";

export default `

#extension GL_OES_standard_derivatives : enable

#define STANDARD

#ifdef PHYSICAL
  #define REFLECTIVITY
  #define CLEARCOAT
  #define TRANSMISSION
#endif

uniform vec3 diffuse;
uniform vec3 emissive;
uniform float roughness;
uniform float metalness;
uniform float opacity;

#ifdef TRANSMISSION
  uniform float transmission;
#endif
#ifdef REFLECTIVITY
  uniform float reflectivity;
#endif
#ifdef CLEARCOAT
  uniform float clearcoat;
  uniform float clearcoatRoughness;
#endif
#ifdef USE_SHEEN
  uniform vec3 sheen;
#endif
varying vec3 vViewPosition;

#ifndef FLAT_SHADED
  varying vec3 vNormal;
  #ifdef USE_TANGENT
    varying vec3 vTangent;
    varying vec3 vBitangent;
  #endif
#endif

${THREE.ShaderChunk["common"]}
${THREE.ShaderChunk["packing"]}
${THREE.ShaderChunk["dithering_pars_fragment"]}
${THREE.ShaderChunk["color_pars_fragment"]}
${THREE.ShaderChunk["uv_pars_fragment"]}
${THREE.ShaderChunk["uv2_pars_fragment"]}
${THREE.ShaderChunk["map_pars_fragment"]}
${THREE.ShaderChunk["alphamap_pars_fragment"]}
${THREE.ShaderChunk["aomap_pars_fragment"]}
${THREE.ShaderChunk["lightmap_pars_fragment"]}
${THREE.ShaderChunk["emissivemap_pars_fragment"]}
${THREE.ShaderChunk["transmissionmap_pars_fragment"]}
${THREE.ShaderChunk["bsdfs"]}
${THREE.ShaderChunk["cube_uv_reflection_fragment"]}
${THREE.ShaderChunk["envmap_common_pars_fragment"]}
${THREE.ShaderChunk["envmap_physical_pars_fragment"]}
${THREE.ShaderChunk["fog_pars_fragment"]}
${THREE.ShaderChunk["lights_pars_begin"]}
${THREE.ShaderChunk["lights_physical_pars_fragment"]}
${THREE.ShaderChunk["shadowmap_pars_fragment"]}
${THREE.ShaderChunk["bumpmap_pars_fragment"]}
${THREE.ShaderChunk["normalmap_pars_fragment"]}
${THREE.ShaderChunk["clearcoat_pars_fragment"]}
${THREE.ShaderChunk["roughnessmap_pars_fragment"]}
${THREE.ShaderChunk["metalnessmap_pars_fragment"]}
${THREE.ShaderChunk["logdepthbuf_pars_fragment"]}
${THREE.ShaderChunk["clipping_planes_pars_fragment"]}

varying vec3 noise;
varying vec2 vUv;

uniform vec2 resolution;
uniform float time;
uniform vec3 color1;
uniform vec3 color2;
uniform vec3 color3;

void main()
{
  ${THREE.ShaderChunk["clipping_planes_fragment"]}
  
    float grayscaleValue = dot(noise.rgb, vec3(0.299, 0.587, 0.114));
    vec3 coolColor = vec3(mix(mix(color1, color2, grayscaleValue), mix(color2, color3, grayscaleValue), grayscaleValue));
    // vec3 coolColor = noise;

    
    vec4 diffuseColor = vec4( coolColor, opacity );
    ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
    vec3 totalEmissiveRadiance = emissive;

    #ifdef TRANSMISSION
      float totalTransmission = transmission;
    #endif

    ${THREE.ShaderChunk["logdepthbuf_fragment"]}
    ${THREE.ShaderChunk["map_fragment"]}
    ${THREE.ShaderChunk["color_fragment"]}
    ${THREE.ShaderChunk["alphamap_fragment"]}
    ${THREE.ShaderChunk["alphatest_fragment"]}
    ${THREE.ShaderChunk["roughnessmap_fragment"]}
    ${THREE.ShaderChunk["metalnessmap_fragment"]}
    ${THREE.ShaderChunk["normal_fragment_begin"]}
    ${THREE.ShaderChunk["normal_fragment_maps"]}
    ${THREE.ShaderChunk["clearcoat_normal_fragment_begin"]}
    ${THREE.ShaderChunk["clearcoat_normal_fragment_maps"]}
    ${THREE.ShaderChunk["emissivemap_fragment"]}
    ${THREE.ShaderChunk["transmissionmap_fragment"]}
    // accumulation
    ${THREE.ShaderChunk["lights_physical_fragment"]}
    ${THREE.ShaderChunk["lights_fragment_begin"]}
    ${THREE.ShaderChunk["lights_fragment_maps"]}
    ${THREE.ShaderChunk["lights_fragment_end"]}
    // modulation
    ${THREE.ShaderChunk["aomap_fragment"]}
    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;
    // this is a stub for the transmission model
    #ifdef TRANSMISSION
      diffuseColor.a *= mix( saturate( 1. - totalTransmission + linearToRelativeLuminance( reflectedLight.directSpecular + reflectedLight.indirectSpecular ) ), 1.0, metalness );
    #endif
    gl_FragColor = vec4( outgoingLight, diffuseColor.a );
    ${THREE.ShaderChunk["tonemapping_fragment"]}
    ${THREE.ShaderChunk["encodings_fragment"]}
    ${THREE.ShaderChunk["fog_fragment"]}
    ${THREE.ShaderChunk["premultiplied_alpha_fragment"]}
    ${THREE.ShaderChunk["dithering_fragment"]}
}
`;
