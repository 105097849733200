import * as THREE from "three";

export default `
#define STANDARD
varying vec3 vViewPosition;
#ifndef FLAT_SHADED
  varying vec3 vNormal;
  #ifdef USE_TANGENT
    varying vec3 vTangent;
    varying vec3 vBitangent;
  #endif
#endif

${THREE.ShaderChunk[ "common" ]}
${THREE.ShaderChunk[ "uv_pars_vertex" ]}
${THREE.ShaderChunk[ "uv2_pars_vertex" ]}
${THREE.ShaderChunk[ "displacementmap_pars_vertex" ]}
${THREE.ShaderChunk[ "color_pars_vertex" ]}
${THREE.ShaderChunk[ "fog_pars_vertex" ]}
${THREE.ShaderChunk[ "morphtarget_pars_vertex" ]}
${THREE.ShaderChunk[ "skinning_pars_vertex" ]}
${THREE.ShaderChunk[ "shadowmap_pars_vertex" ]}
${THREE.ShaderChunk[ "logdepthbuf_pars_vertex" ]}
${THREE.ShaderChunk[ "clipping_planes_pars_vertex" ]}

vec3 magic(vec3 p, int n, float distortion) {
  float dist = distortion;

  float x = sin((p[0] + p[1] + p[2]) * 5.0);
  float y = cos((-p[0] + p[1] - p[2]) * 5.0);
  float z = -cos((-p[0] - p[1] + p[2]) * 5.0);

  if (n > 0) {
    x *= dist;
    y *= dist;
    z *= dist;
    y = -cos(x - y + z);
    y *= dist;

    if (n > 1) {
      x = cos(x - y - z);
      x *= dist;

      if (n > 2) {
        z = sin(-x - y - z);
        z *= dist;

        if (n > 3) {
          x = -cos(-x + y - z);
          x *= dist;

          if (n > 4) {
            y = -sin(-x + y + z);
            y *= dist;

            if (n > 5) {
              y = -cos(-x + y + z);
              y *= dist;

              if (n > 6) {
                x = cos(x + y + z);
                x *= dist;

                if (n > 7) {
                  z = sin(x + y - z);
                  z *= dist;

                  if (n > 8) {
                    x = -cos(-x - y + z);
                    x *= dist;

                    if (n > 9) {
                      y = -sin(x - y + z);
                      y *= dist;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  if (dist != 0.0) {
    dist *= 2.0;
    x /= dist;
    y /= dist;
    z /= dist;
  }

  return vec3(0.5 - x, 0.5 - y, 0.5 - z);
}

varying vec2 vUv;
varying vec3 noise;

uniform float time;
uniform float scale;
uniform float distortion;
uniform int depth;

void main() {
  ${THREE.ShaderChunk[ "uv_vertex" ]}
  ${THREE.ShaderChunk[ "uv2_vertex" ]}
  ${THREE.ShaderChunk[ "color_vertex" ]}

  ${THREE.ShaderChunk[ "beginnormal_vertex" ]}
  ${THREE.ShaderChunk[ "morphnormal_vertex" ]}
  ${THREE.ShaderChunk[ "skinbase_vertex" ]}
  ${THREE.ShaderChunk[ "skinnormal_vertex" ]}
  ${THREE.ShaderChunk[ "defaultnormal_vertex" ]}

  #ifndef FLAT_SHADED // Normal computed with derivatives when FLAT_SHADED
    vNormal = normalize( transformedNormal );
    #ifdef USE_TANGENT
      vTangent = normalize( transformedTangent );
      vBitangent = normalize( cross( vNormal, vTangent ) * tangent.w );
    #endif
  #endif

  ${THREE.ShaderChunk[ "begin_vertex" ]}
  ${THREE.ShaderChunk[ "morphtarget_vertex" ]}
  ${THREE.ShaderChunk[ "skinning_vertex" ]}
  ${THREE.ShaderChunk[ "displacementmap_vertex" ]}
  ${THREE.ShaderChunk[ "project_vertex" ]}
  ${THREE.ShaderChunk[ "logdepthbuf_vertex" ]}
  ${THREE.ShaderChunk[ "clipping_planes_vertex" ]}

  vViewPosition = - mvPosition.xyz;
  
  ${THREE.ShaderChunk[ "worldpos_vertex" ]}
  ${THREE.ShaderChunk[ "shadowmap_vertex" ]}
  ${THREE.ShaderChunk[ "fog_vertex" ]}

   vUv = uv;

   vec3 magicRes = magic(vec3(position.xy, time) * scale, depth, distortion);
   noise = magicRes;

   vec3 newPosition = position + normal * ((noise.x+noise.y+noise.z)/3.);
   gl_Position = projectionMatrix * modelViewMatrix *    vec4(newPosition, 1.0);
}
`